<template>
  <v-row>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Nombres</strong></label>
          <v-text-field
            ref="names"
            v-model.trim="user.names"
            prepend-icon="mdi-account"
            label="Nombres"
            required
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Apellidos</strong></label>
          <v-text-field
            ref="firstName"
            v-model.trim="user.last_name"
            prepend-icon="mdi-account"
            label="Apellido paterno"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Tipo de documento</strong></label>
          <v-select
            v-model="user.document_id"
            :items="documents"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-clipboard-file-outline"
            label="Tipo de documento"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Número de documento</strong></label>
          <v-text-field
            ref="documentNumber"
            v-model="user.document_number"
            prepend-icon="mdi-clipboard-file-outline"
            label="Número de documento"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Rol</strong></label>
          <v-select
            v-model="user.role"
            :items="roles"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-clipboard-file-outline"
            label="Rol"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Estado</strong></label>
          <v-select
            v-model="user.status"
            :items="status"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-clipboard-file-outline"
            label="Estado"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Email</strong></label>
          <v-text-field
            ref="email"
            v-model="user.email"
            prepend-icon="mdi-email-mark-as-unread"
            label="Email"
            required
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Contraseña</strong></label>
          <v-text-field
            id="password"
            v-model="user.password"
            color="primary"
            :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye-outline'"
            :type="show1 ? 'text' : 'password'"
            placeholder="Ingresar tu contraseña"
            prepend-inner-icon="mdi-lock-outline"
            name="password"
            @click:append="show1 = !show1"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Confirmar Contraseña</strong></label>
          <v-text-field
            id="password_confirm"
            v-model="user.password_confirm"
            color="primary"
            :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye-outline'"
            :type="show2 ? 'text' : 'password'"
            placeholder="Ingresar tu contraseña"
            prepend-inner-icon="mdi-lock-outline"
            name="password_confirm"
            @click:append="show2 = !show2"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Localidad</strong></label>
          <v-select
            v-model="user.location_id"
            :items="locations"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-clipboard-file-outline"
            label="Localidad"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <foto-upload :photo_url="user.photo_url">
          <label slot="title"><strong>Foto del Usuario</strong></label>
        </foto-upload>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row class="fill-height">
        <Files :files="user.files" :module="module">
          <label slot="title"><strong>Archivos</strong></label>
        </Files>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import FotoUpload from "@/components/util/Foto.vue";
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Files from "@/components/util/File.vue";

export default {
  name: "form-user",
  props: ["user"],
  components: { FotoUpload,Files },
  watch: {},
  validations() {
    return {
      user: {
        names: required,
        last_name: required,
        document_id: required,
        document_number: required,
        role: required,
        status: required,
        email: required,
        password: required,
        password_confirm: required,
      },
    };
  },
  data: () => ({
    show1: false,
    show2: false,
    module: "user",
    roles: [
      {
        id: "admin",
        name: "Administrador",
      },
      {
        id: "recepcionista",
        name: "Recepcionista",
      },
    ],
    status: [
      {
        id: 0,
        name: "Inactivo",
      },
      {
        id: 1,
        name: "Activo",
      },
    ],
  }),
  computed: {
    ...mapState({
      documents: (state) => state.util.documents,
      locations: (state) => state.util.locations,
    }),
  },
  methods: {
    ...mapActions(["fetchDocuments", "fetchLocations"]),
    getFetch() {
      this.fetchDocuments();
      this.fetchLocations();
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
</style>