<template>
  <v-container fluid class="ml-10">
    <br /><br />
    <v-row dense id="rowAddEdit">
      <v-col cols="12">
        <h1 class="mr-8" v-if="id == null">
          <v-btn fab dark small color="primary" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Agregar Usuario
        </h1>

        <h1 class="mr-8" v-if="id != null">
          <v-btn fab dark small color="primary" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Editar Usuario
        </h1>
      </v-col>

      <v-col cols="12">
        <br /><br />
        <form-user :user="iuser"></form-user>
      </v-col>

      <v-col cols="12" class="text-center">
        <br />
        <br />
        <v-btn
          large
          class="button-save"
          color="primary"
          dark
          dense
          @click="save()"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import FormUser from "@/components/forms/FormUser.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "UserAddEdit",
  props: ["id"],
  components: { FormUser },
  watch: {
    userSusccesMessage: function (value) {
      if (value) {
        this.mensaje(true, "success", this.userSusccesMessage, 5000);
        if (!this.id) this.iuser = {};
        this.$router.go(-1);
      }
    },
    errorMaxFotos: function (value) {
      if (value) this.mensaje(true, "error", value, 5000);
    },
    userError: function (value) {
      if (value) this.mensaje(true, "error", this.userError.errorMessage, 5000);
    },
    user: function (value) {
      if (this.id) {
        this.iuser = Object.assign({}, value);
      } else {
        this.iuser = Object.assign({ ...this.iuser }, { ...value });
      }
    },
    photo_url: function (value) {
      this.iuser = Object.assign({}, { ...this.iuser, photo_url: value });
    },
    fileDelete(value) {
      this.iuser = Object.assign({}, this.user);
    },
  },
  computed: {
    ...mapState({
      errorMaxFotos: (state) => state.util.errorMaxFotos,
      fetchingData: (state) => state.fetchingData,
      userError: (state) => state.user.userError,
      userSusccesMessage: (state) => state.user.userSusccesMessage,
      user: (state) => state.user.user,
      photo_url: (state) => state.util.photo_url,
      fileDelete: (state) => state.util.fileDelete,
    }),
  },
  data: () => ({
    iuser: {},
    snackbar: false,
    color: "",
    text: "",
  }),
  methods: {
    ...mapActions(["addUser", "editUser", "getUserById"]),
    save() {
      // console.log(this.iuser);
      if (this.iuser.password == "") {
        delete this.iuser.password;
        delete this.iuser.confirm_password;
      }
      if (this.id) {
        //update
        this.editUser({ user: this.iuser });
      } else {
        this.addUser({ user: this.iuser });
        //create
      }
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFetch() {
      if (this.id) {
        this.getUserById({ id: this.id });
      }
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
#rowAddEdit {
  padding-right: 80px !important;
}
</style>